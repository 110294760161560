import React, { useState, useRef, useEffect } from 'react';

import { breakpoints, colors } from 'styles/cp';

export interface AutoGrowingInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxWidth?: number;
  isError?: boolean;
  cleanValue?: string;
}

const AutoGrowingInput: React.FC<AutoGrowingInputProps> = ({
  value,
  onChange,
  maxWidth = 350,
  isError,
  cleanValue,
  ...props
}) => {
  const [inputWidth, setInputWidth] = useState('auto');
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  // Dynamic input width
  useEffect(() => {
    if (spanRef.current) {
      const newWidth = spanRef.current.scrollWidth;
      setInputWidth(`${newWidth + 15}px`);
    }
  }, [value]);

  // if theres no value input - still show the empty input itself with the border
  useEffect(() => {
    if ((cleanValue ?? value) === '') {
      setInputWidth('80px');
    }
  }, [value]);

  return (
    <div className="auto-growing-input-wrapper">
      <input
        ref={inputRef}
        value={value}
        onChange={onChange}
        className="auto-growing-input"
        {...props}
      />
      <span className="input-span" ref={spanRef}>
        {value}
      </span>
      <style jsx>
        {`
          .auto-growing-input-wrapper {
            position: relative;
            display: inline-block;
          }

          .auto-growing-input {
            overflow: hidden;
            white-space: nowrap;
            outline: none;
            border: none;
            border-bottom: 1px solid ${isError ? colors.redError : colors.gray03};
            box-sizing: content-box;
            width: ${inputWidth};
            max-width: ${maxWidth}px;

            @media (max-width: ${breakpoints.md}) {
              max-width: ${maxWidth - 120}px;
            }
          }

          .input-span {
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
            white-space: nowrap;
            height: 0;
          }
        `}
      </style>
    </div>
  );
};

export default AutoGrowingInput;
